
  @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');



.centered h1{
position: absolute;
top: 67%;
/* left: 50%; */
/* transform: translate(-50%, -50%); */
color: white;
width: 100%; 
font-family: 'Oswald', sans-serif;}




 @media (max-width: 500px)  {
    .centered h1{
        position: absolute;
        top: 10%;
        /* left: 50%; */
        /* transform: translate(-50%, -50%); */
        color: white;
        width: 100%; 
        }
        .imgcenterd h1{
            display: none;
        }
        .para  p{
            display: none;
        }
       
}

@media (max-width: 1280px)  {
    .centered h1{
        position: absolute;
        top: 40%;
        /* left: 50%; */
        /* transform: translate(-50%, -50%); */
        color: white;
        width: 100%; 
        }
        .imgcenterd h1{
            display: none;
        }
        .para  p{
            display: none;
        }
}
@media (max-width: 414px)  {
    .centered h1{
        position: absolute;
        top: 15%;
        /* left: 50%; */
        /* transform: translate(-50%, -50%); */
        color: white;
        width: 100%; 
        }
        .imgcenterd h1{
            display: none;
        }
        .para  p{
            display: none;
        }
}

/*
@media (min-width: 200px)  {
    .centered h1{
        margin-top: -28%;
    }
}

@media (min-width: 768px)  {
    .centered h1{
        margin-top:-7%;
    }
}

@media (min-width: 800px)  {
    .centered h1{
        margin-top: -10%;
    }
} */



/* AbutDescr.js filestylin */
.imgcenterd{
    position: absolute;
top: 135%;
left: 50%;
transform: translate(-50%, -50%);
color: white;
width: 100%; 
}







