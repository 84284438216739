@media (max-width: 768px) {
  .hero-wrapper1 h1 {
      font-size: 40px; /* Reduced font size for mobile view */
  }
  
  .hero-wrapper1 p {
      font-size: 16px; /* Reduced font size for mobile view */
  }

  section h1 {
      font-size: 40px; /* Reduced font size for mobile view */
  }

  section p {
      font-size: 18px; /* Reduced font size for mobile view */
  }

  .container p {
      font-size: 18px; /* Reduced font size for mobile view */
  }

  .container h1 {
      font-size: 40px; /* Reduced font size for mobile view */
  }

  .btn {
      width: 100%; /* Make button width responsive */
  }
}

.laptop-view {
  height: 100vh;
}

.image-container {
  width: 50%;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-container {
  width: 50%;
  background-color: #f0f0f0;
}

@media (max-width: 992px) {
  .laptop-view {
      height: auto;
      flex-direction: column;
  }

  .image-container {
      display: none;
  }

  .content-container {
      width: 100%;
  }
}

.hero-wrapper1 {
  height: 100vh;
  background-image: url("../assets/images/Collobaration1.png"); /* Adjust the path if needed */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.hero-overlay1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Optional: add overlay for better text readability */
}

@media (max-width: 992px) {
  .hero-wrapper1 {
      background-attachment: scroll;
  }
}
