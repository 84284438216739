header{
    height: 200px;
    background: #bbb;
    text-align: center;
    padding: 30px 0;
}

.sticky-container{
    width: 80%;
    margin: 0px auto;
    clear: both;
}

.sticky-container::before, .sticky-container::after{
    content: '';
    display: table;
    clear: both;
}

.right-1{
    width: 55dvw;
    display: inline-block;
    vertical-align: top;
    float: right;
    margin-top: -43%;
    margin-bottom: 3.5rem;
    padding-left: 16rem;
}


.left-1{
    width: 30dvw;
    height: 80vh;
    display: inline-block;
    float: left;
    position: sticky;
    top: 50px;
    margin-bottom: 7.5rem;
}