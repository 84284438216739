.slider {
    height: 100vh;
    width: 100%;
    position: relative;
    overflow-x: hidden;
}

.slider .list .item {
    position: absolute;
    inset: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.slider .list .item.active {
    opacity: 1;
    z-index: 10;
}

.slider .list .item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)); Adjust opacity as needed */
}

.slider .list .item img {
    width: 250vh;
    height: 150vh;
    object-fit: cover;
}


.slider .list .item .content {
    position: absolute;
    left: 5%;
    top: 24%;
    width: 600px;
    max-width: 30%;
    z-index: 1;
    color: #fff;
}

@keyframes showContent {
    to {
        transform: translateY(0);
        filter: blur(0);
        opacity: 1;
    }
}

.slider .list .item.active p:nth-child(1),
.slider .list .item.active h2,
.slider .list .item.active p:nth-child(3) {
    transform: translateY(30px);
    filter: blur(20px);
    opacity: 0;
    animation: showContent 0.5s 0.7s ease-in-out 1 forwards;
}

.slider .list .item.active h2 {
    animation-delay: 1s;
}

.slider .list .item.active p:nth-child(3) {
    animation-duration: 1.3s;
}

.arrows {
    position: absolute;
    bottom: 10px; /* Moved to the bottom */
    left: 60%; /* Adjusted position */
    transform: translateX(-50%); /* Center horizontally */
    z-index: 100;
}

.arrows button {
    background-color: black;
    color: white;
    border: none;
    font-family: monospace;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    font-size: x-large;
    transition: 0.5s;
}

.thumbnail {
    position: absolute;
    bottom: 64px; /* Moved to the bottom */
    left: 69%; /* Adjusted position */

    transform: translateX(-50%);
    z-index: 11;
    display: flex;
    gap: 18px;
    width: calc(100% - 100px); /* Adjusted width */
    padding: 0 50px;
    box-sizing: border-box;
    overflow: auto;
    justify-content: center;
    
}

.thumbnail::-webkit-scrollbar {
    width: 0;
}

.thumbnail .item {
    width: 150px; /* Adjusted width */
    height: 40vh; /* Adjusted height */
    filter: brightness(0.5);
    transition: filter 0.5s;
    flex-shrink: 0;
}

.thumbnail .item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.thumbnail .item.active {
    filter: brightness(1.5);
}

